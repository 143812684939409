<template>
  <div class="hint">
    <div class="center">
      <img class="img" :src="hintData.imgurl" />
      <p class="hint-text">{{ hintData.text }}</p>
    </div>
    <div class="footer">
      <el-button type="primary" @click="goLogin"> 去登录 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hintData: {
        imgurl: require("@/assets/imgs/indexIndexIndex/yes.png"),
        text: "注册成功",
      },
    };
  },
  methods: {
    //去登录
    goLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.hint {
  width: 380px;
  height: 390px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .img {
      width: 80px;
      height: 62px;
    }
    .hint-text {
      color: #299ffe;
      font-size: 14px;
      margin-top: 12px;
    }
  }
  .footer {
    height: 100px;
    /deep/.el-button {
      width: 300px;
      height: 42px;
      background-color: #fff;
      border: 1px solid #299ffe;
      color: #299ffe;
    }
    /deep/.el-button:hover {
      background-color: #299ffe;
      color: #fff;
    }
  }
}
</style>