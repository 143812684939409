<template>
  <div>
    <moban>
      <div class="mycenter">
        <h2 class="title">
          <span>{{ isHint ? "注册" : "提示" }}</span>
          <i
            class="close el-icon-close"
            @click="isHint = true"
            v-if="!isHint"
          ></i>
          <i
            class="close el-icon-close"
            v-else-if="isHint"
            @click="closeReg"
          ></i>
        </h2>
        <div>
          <!-- 中心内容 -->
          <div class="center" v-if="isHint">
            <registerContent
              :isHint="isHint"
              @isHintChange="isHint = false"
            ></registerContent>
          </div>
          <!-- 提示框 -->
          <div class="hint" v-else-if="!isHint">
            <registerSucc></registerSucc>
          </div>
        </div>
      </div>
    </moban>
  </div>
</template>

<script>
import moban from "@/components/indexIndexIndex/moban.vue";
import registerContent from "./c/registerContent.vue";
import registerSucc from "./c/registerSucc.vue";

export default {
  components: {
    moban,
    registerContent,
    registerSucc,
  },
  data() {
    return {
      checked: true,
      loading: false, //按钮加载
      isHint: true, //提示
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    closeReg() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/" });
        return false;
      } else {
        this.$router.back();
      }
    },
  },
  // mounted() {
  //   console.log(languagePackage[language]);
  // },
};
</script>

<style lang="less" scoped>
.mycenter {
  width: 380px;
  height: 490px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -7px 5px 4px 0px rgba(122, 122, 122, 0.14);

  .title {
    font-size: 24px;
    color: #333;
    text-align: center;
    position: relative;
    height: 60px;
    border-bottom: 1px solid #eee;
    margin-top: 30px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 4px;
    font-size: 24px;
    color: #666;
    cursor: pointer;
  }
}
</style>