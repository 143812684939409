<template>
  <div class="register-content">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 电话 -->
      <div class="tel">
        <el-form-item prop="account">
          <el-input
            v-model="ruleForm.account"
            placeholder="手机号"
            @focus="focusTel"
            @blur="blurTel"
          ></el-input>
        </el-form-item>
        <div class="area-code">+86</div>
      </div>
      <!-- 密码 -->
      <div class="pwd">
        <el-form-item prop="pass">
          <el-input
            v-model="ruleForm.pass"
            placeholder="密码"
            show-password
          ></el-input>
          <i class="account-icon el-icon-lock"></i>
        </el-form-item>
      </div>
      <!-- 验证码 -->
      <div class="auth-code">
        <el-form-item prop="code">
          <el-input
            v-model="ruleForm.code"
            placeholder="请输入验证码"
          ></el-input>
          <el-button @click="getAuthCode" :loading="loading">
            {{ loading ? countDown + "s后重新发送" : "获取验证码" }}
          </el-button>
        </el-form-item>
      </div>
      <!-- 协议 -->
      <div class="protocol">
        <el-checkbox v-model="checked">
          我已同意《xxx用户使用协议》和《xxx
        </el-checkbox>
      </div>
      <!-- 底部 -->
      <el-form-item>
        <footer>
          <el-button @click="hintSucceed('ruleForm')">注册</el-button>
          <div class="start-login">
            已有账号?<router-link to="/login">直接登录</router-link>
          </div>
        </footer>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { checkAccount, checkPassword } from "@/utils/tools";
import { do_register, do_sendmsg } from "@/api/dologin";

import md5 from "md5";

export default {
  props: {
    isHint: {
      type: Boolean,
    },
  },
  data() {
    return {
      ruleForm: {
        pass: "",
        account: "",
      },
      rules: {
        pass: [{ validator: checkPassword, trigger: "blur" }],
        account: [{ validator: checkAccount, trigger: "blur" }],
      },
      checked: true,
      loading: false,
      countDown: 60, //倒计时
    };
  },
  methods: {
    // 注册
    hintSucceed(ruleForm) {
      if (!this.checked) {
        this.$notify({
          type: "error",
          message: "请阅读用户协议并勾选",
        });
        return;
      }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let phone = this.ruleForm.account; //手机号
          let password = this.ruleForm.pass; //密码
          let yzm = this.ruleForm.code; //验证码

          let data = { phone, password, yzm };
          console.log(data);
          do_register(data)
            .then((res) => {
              //   console.log(res);

              let user = {
                user_id: res.data.user_id,
                user_token: res.data.user_token,
                head_img: res.data.head_img,
                nickname: res.data.nickname,
              };
              this.$store.commit("set_user_info", user);

              this.$notify({
                type: "success",
                message: "注册成功",
              });
              //   this.$emit("isHintChange", this.isHint);
              this.$router.push("/");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.logining = false;
            });
        } else {
          return false;
        }
      });
    },
    focusTel() {
      document.querySelector(".area-code").style.borderRight = 0;
    },
    blurTel() {
      document.querySelector(".area-code").style.borderRight = "1px solid #eee";
    },
    //获取验证码
    getAuthCode() {
      let phone = this.ruleForm.account; //手机号
      if (!phone) {
        this.$notify({
          type: "error",
          message: "请输入手机号",
        });
        return;
      }
      let time_stamp = Math.floor(new Date().getTime() / 1000) + ""; //时间戳秒级
      let sign = md5(md5(phone + "jianzhan" + time_stamp)); //数据签名
      let scene = "注册";
      let data = { phone, time_stamp, sign, scene };

      this.logining = true;
      do_sendmsg(data)
        .then((res) => {
          this.loading = true;
          this.countDownFn();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.logining = false;
        });
    },
    // 倒计时
    countDownFn() {
      let timer = window.setInterval(() => {
        this.countDown--;
        this.loading = true;
        if (this.countDown < 0) {
          this.countDown = 60;
          this.loading = false;
          window.clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.register-content {
  padding: 20px 30px;
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
  // 手机
  .tel {
    position: relative;

    .area-code {
      position: absolute;
      left: 8px;
      top: 12px;
      color: #333;
      font-size: 14px;
      border-right: 1px solid #eee;
      padding-right: 6px;
    }
    /deep/.el-input__inner {
      padding-left: 40px;
      width: 322px;
      height: 42px;
    }
  }
  //验证码
  .auth-code {
    display: flex;
    margin-top: 20px;
    /deep/.el-form-item__content {
      display: flex;
    }
    /deep/.el-form-item {
      margin-bottom: 0;
    }

    /deep/.el-input__inner {
      padding-left: 15px;
      width: 220px;
      height: 42px;
    }
    .el-input {
      width: 220px;
    }
    .el-button {
      width: 102px;
      padding: 0px;
      color: #299ffe;
      border: 1px solid #299ffe;
      font-size: 14px;
    }
    /deep/.el-button [class*="el-icon-"] + span {
      margin-left: -2px;
      font-size: 12px;
    }
  }
  //密码
  .pwd {
    margin-top: 20px;
    position: relative;
    /deep/.el-input__inner {
      padding-left: 40px;
      width: 322px;
      height: 42px;
    }
    .account-icon {
      position: absolute;
      top: 12px;
      left: 10px;
      font-size: 18px;
    }
  }
  //协议
  .protocol {
    margin-top: 20px;
  }

  // 底部
  footer {
    margin-top: 40px;
    .el-button {
      background-color: #299ffe;
      width: 320px;
      height: 42px;
      color: #fff;
    }
    .start-login {
      margin-top: 8px;
      text-align: right;
      color: #333;
      > a {
        color: #299ffe;
      }
    }
  }
}
</style>